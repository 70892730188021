// src/lib/apiClient.ts
import createClient from 'openapi-fetch'
import type { paths } from './openapi-definition/generated/schema'

interface ApiClientConfig {
  baseUrl: string
  authorization: string
  saasTenantEntityId: string
}
export class ApiClient {
  private static instance: ReturnType<typeof createClient<paths>> | null = null
  private static config: ApiClientConfig | null = null

  private constructor() {} // Private constructor to prevent direct construction calls with the `new` operator.

  /**
   * 
   * @param config 
   * @deprecated Should not depend on this, use `newInstance` instead
   */
  public static configure(config: ApiClientConfig): void {
    ApiClient.config = config
    // Reinitialize the instance with new config if already exists
    if (ApiClient.instance) {
      ApiClient.instance = createClient({
        baseUrl: config.baseUrl,
        headers: {
          Authorization: `${config.authorization}`,
          SAAS_TENANT_ENTITY_ID: config.saasTenantEntityId,
        },
      })
    }
  }

  /**
   * 
   * @returns 
   * @throws error
   */
  public static getClient(): ReturnType<typeof createClient<paths>> {
    if (!ApiClient.instance) {
        throw new Error('ApiClient is not configured. Please call configure first.')
    }
    return ApiClient.instance
  }

  /**
   * creates a and sets a new instance of the api client with the config
   * @param config 
   */
  public static newInstance(config: ApiClientConfig): void {
    ApiClient.config = config
    ApiClient.instance = createClient({
      baseUrl: config.baseUrl,
      headers: {
        Authorization: `${config.authorization}`,
        SAAS_TENANT_ENTITY_ID: config.saasTenantEntityId,
      },
    })
  }
  
    /**
   * 
   * @returns 
   */
    public static getInstance(): ReturnType<typeof createClient<paths>> | null {
      return ApiClient.instance
    }

}
